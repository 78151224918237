const shuffle = (a) => {
  return a.reduce((l, e, i) => {
    const j = Math.floor(Math.random() * (a.length - i) + i);
    [a[i], a[j]] = [a[j], a[i]];
    return a;
  }, a);
};

export const fixedShuffleIndex = (a, f) => {
  const w = shuffle(
    a.reduce((acc, e, i) => {
      if (!f[i]) {
        acc.push(e);
      }
      return acc;
    }, [])
  );
  return f.reduce((acc, e, i) => {
    if (e) {
      acc.splice(i, 0, a[i]);
    }
    return acc;
  }, w);
};

export const cacheImages = async (srcArray) => {
  const promises = await srcArray.map((src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = src;
      img.onload = function () {
        resolve(this);
      };
      img.onerror = function () {
        reject();
      };
    });
  });
  const images = await Promise.all(promises);
  return images;
};
